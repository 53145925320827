"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogPermissionEnum = exports.TranslationPermissionEnum = exports.UserGroupPermissionEnum = exports.UserPermissionEnum = void 0;
var UserPermissionEnum;
(function (UserPermissionEnum) {
    UserPermissionEnum["USER_READ"] = "USER_READ";
    UserPermissionEnum["USER_WRITE"] = "USER_WRITE";
    UserPermissionEnum["DRIVER_VIEW"] = "DRIVER_VIEW";
    UserPermissionEnum["LOGISTIC_VIEW"] = "LOGISTIC_VIEW";
})(UserPermissionEnum = exports.UserPermissionEnum || (exports.UserPermissionEnum = {}));
var UserGroupPermissionEnum;
(function (UserGroupPermissionEnum) {
    UserGroupPermissionEnum["USER_GROUP_READ"] = "USER_GROUP_READ";
    UserGroupPermissionEnum["USER_GROUP_WRITE"] = "USER_GROUP_WRITE";
})(UserGroupPermissionEnum = exports.UserGroupPermissionEnum || (exports.UserGroupPermissionEnum = {}));
var TranslationPermissionEnum;
(function (TranslationPermissionEnum) {
    TranslationPermissionEnum["TRANSLATION_READ"] = "TRANSLATION_READ";
    TranslationPermissionEnum["TRANSLATION_WRITE"] = "TRANSLATION_WRITE";
})(TranslationPermissionEnum = exports.TranslationPermissionEnum || (exports.TranslationPermissionEnum = {}));
var LogPermissionEnum;
(function (LogPermissionEnum) {
    LogPermissionEnum["LOG_READ"] = "LOG_READ";
})(LogPermissionEnum = exports.LogPermissionEnum || (exports.LogPermissionEnum = {}));
