"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["onSubmit"];
const _hoisted_2 = { class: "relative flex flex-wrap" };
const _hoisted_3 = { class: "relative flex flex-wrap" };
const _hoisted_4 = {
    type: "submit",
    class: "btn btn-primary w-auto md:h-full"
};
const i18n_1 = require("../common/i18n");
const vue_router_1 = require("vue-router");
const toastStore_1 = require("../stores/toast/toastStore");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PackingSlipsLoaderForm',
    emits: ['submit'],
    setup(__props, { emit }) {
        // megjelenit egy urlapot, majd azt elkuldve visszaadja az urlapon szereplo parametereket
        // ezekhez a parameterekhez kivulrol egy event-tel lehet hozzaferni
        // DATA
        let axaptaShipmentId = '';
        let packingSlipId = '';
        const router = (0, vue_router_1.useRouter)();
        // ACTIONS
        const submitForm = () => {
            if (axaptaShipmentId === '' && packingSlipId === '') {
                (0, toastStore_1.useToastStore)().addToast({
                    message: (0, i18n_1.trans)('you_have_to_enter_an_id', 'You have to enter an ID', 'packing_slips_loader_form'),
                    type: "danger",
                });
                return;
            }
            emit('submit', { axaptaShipmentId, packingSlipId });
            // empty the inputs
            axaptaShipmentId = '';
            packingSlipId = '';
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("form", {
                onSubmit: (0, vue_2.withModifiers)(submitForm, ["prevent"]),
                class: "grid grid-cols-1 md:grid-cols-3 gap-4"
            }, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("input", {
                        type: "text",
                        class: "w-full py-4 px-4",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((0, vue_2.isRef)(axaptaShipmentId) ? (axaptaShipmentId).value = $event : axaptaShipmentId = $event)),
                        placeholder: "Shipment Id"
                    }, null, 512), [
                        [vue_2.vModelText, (0, vue_2.unref)(axaptaShipmentId)]
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("input", {
                        type: "text",
                        class: "w-full py-4 px-4",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((0, vue_2.isRef)(packingSlipId) ? (packingSlipId).value = $event : packingSlipId = $event)),
                        placeholder: "Packing Slip Id"
                    }, null, 512), [
                        [vue_2.vModelText, (0, vue_2.unref)(packingSlipId)]
                    ])
                ]),
                (0, vue_2.createElementVNode)("button", _hoisted_4, (0, vue_2.toDisplayString)((0, vue_2.unref)(i18n_1.trans)('query', 'Query', 'preparator')), 1)
            ], 40, _hoisted_1));
        };
    }
});
