"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class UserRequest {
    constructor(plainPassword = null, name = '', phone = '', email = '', groups = [], extraEmails = []) {
        this.plainPassword = plainPassword;
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.groups = groups;
        this.extraEmails = extraEmails;
    }
}
exports.default = UserRequest;
