"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToastStore = void 0;
const pinia_1 = require("pinia");
const ToastPosition_1 = require("./ToastPosition");
exports.useToastStore = (0, pinia_1.defineStore)('toastStore', {
    state: () => ({
        toasts: {
            [ToastPosition_1.ToastPosition.TopLeft]: [],
            [ToastPosition_1.ToastPosition.TopRight]: [],
            [ToastPosition_1.ToastPosition.BottomLeft]: [],
            [ToastPosition_1.ToastPosition.BottomRight]: [],
        },
        incrementId: 0
    }),
    getters: {},
    actions: {
        addToast(toast) {
            let defaultToast = {
                active: true,
                title: undefined,
                message: undefined,
                messageHtml: undefined,
                showIcon: true,
                icon: 'marso-icon-notification',
                showClose: true,
                type: 'info',
                timer: 5,
                position: ToastPosition_1.ToastPosition.TopRight,
            };
            let mergedToast = Object.assign(defaultToast, toast);
            this.incrementId++;
            mergedToast.id = this.incrementId;
            if (mergedToast.position !== undefined) {
                this.toasts[mergedToast.position].push(mergedToast);
            }
            if (mergedToast.timer && 0 < mergedToast.timer) {
                if (mergedToast.id) {
                    setTimeout(this.removeToast.bind(this, mergedToast.id), mergedToast.timer * 1000);
                }
            }
        },
        removeToast(id) {
            for (const position of Object.keys(this.toasts)) {
                this.toasts[position] = this.toasts[position].filter((e) => {
                    return e.id !== id;
                });
            }
        },
        addFadingErrorToast(errorMessage) {
            this.addToast({
                active: true,
                title: undefined,
                message: undefined,
                messageHtml: errorMessage,
                showIcon: true,
                icon: 'marso-icon-notification',
                showClose: true,
                type: 'danger',
                timer: 5,
                position: ToastPosition_1.ToastPosition.TopRight,
            });
        }
    },
});
