"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Filters {
    constructor() {
        this.page = 1;
        this.levelName = [];
        this.tag = '';
        this.method = '';
        this.orderBy = 'createdAt';
        this.orderDirection = 'desc';
        this.itemsPerPage = 50;
    }
    createQuery() {
        let query = {};
        query.page = this.page;
        query.order = [];
        query.order[this.orderBy] = this.orderDirection;
        if (this.levelName.length) {
            query.levelName = [];
            this.levelName.forEach((item) => {
                query.levelName.push(item);
            });
        }
        if ('' !== this.tag) {
            query.tag = this.tag;
        }
        if ('' !== this.method) {
            query.method = this.method;
        }
        query.itemsPerPage = this.itemsPerPage;
        return query;
    }
}
exports.default = Filters;
