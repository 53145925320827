'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackingSlipEditStatus = void 0;
var PackingSlipEditStatus;
(function (PackingSlipEditStatus) {
    // ok
    PackingSlipEditStatus["VALID"] = "All data is good.";
    // hibas az ar
    PackingSlipEditStatus["INVALID_PRICE"] = "Invalid price.";
    // tul nagy a kulonbseg
    PackingSlipEditStatus["WARNING_DIFFERENCE"] = "Difference is too big between the price and estimated price.";
})(PackingSlipEditStatus = exports.PackingSlipEditStatus || (exports.PackingSlipEditStatus = {}));
