"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["for"];
const _hoisted_2 = { class: "grid" };
const _hoisted_3 = ["id", "name", "placeholder", "maxlength", "value"];
const _hoisted_4 = {
    key: 0,
    class: "row-start-1 col-start-1 font-bold text-red p-2 opacity-70 border-1 border-red rounded bg-white justify-self-center place-self-center bottom-3/4 relative grid before:place-self-center before:w-2 before:h-2 before:bg-white before:block before:border-r-1 before:border-b-1 before:border-red before:absolute before:-bottom-1 before:rotate-45"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'BasicInput',
    props: {
        needLabel: {
            type: Boolean,
            default: true
        },
        labelText: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true
        },
        disabled: Boolean,
        readonly: Boolean,
        modelValue: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxLength: {
            type: Number,
            required: false
        },
        wrapperClasses: {
            type: String,
            default: "block"
        },
        labelClasses: {
            type: String,
            default: "block"
        },
        inputClasses: {
            type: String,
            default: "block"
        },
        errorMessage: {
            type: String,
            default: ""
        },
    },
    emits: ["update:modelValue", "value:change"],
    setup(__props, { emit }) {
        const props = __props;
        const emitModelValue = (event) => {
            const target = event.target;
            if (target instanceof HTMLInputElement) {
                emit('update:modelValue', props.type === 'number' ? Number(target.value).valueOf() : target.value);
            }
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(__props.wrapperClasses)
            }, [
                (__props.needLabel)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", {
                        key: 0,
                        for: __props.name
                    }, (0, vue_2.toDisplayString)(__props.labelText), 9, _hoisted_1))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("input", {
                        type: "text",
                        id: __props.name,
                        class: (0, vue_2.normalizeClass)(["row-start-1 col-start-1", __props.inputClasses]),
                        name: __props.name,
                        placeholder: __props.placeholder,
                        maxlength: __props.maxLength,
                        value: __props.modelValue,
                        onInput: emitModelValue,
                        onChange: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('value:change', $event)))
                    }, null, 42, _hoisted_3),
                    (__props.errorMessage)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_4, (0, vue_2.toDisplayString)(__props.errorMessage), 1))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ], 2));
        };
    }
});
